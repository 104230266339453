const typeQuestions = {
  OBJECTIVE_DISCURSIVE: { 
    id: 0, 
    name:'Objetiva e Discursiva' 
  },
  YES_NO: {
    id: 1,
    name: 'Sim ou Não'
  },
  MULTIPLES_CHOICES: {
    id: 2,
    name: 'Múltiplas Escolhas'
  },
  RATING: {
    id: 3,
    name: 'Avaliação'
  },
  DISCURSIVE: {
    id: 4,
    name: 'Discursiva'
  }
}

Object.freeze(typeQuestions)

export default typeQuestions
