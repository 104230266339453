import GuidUtils from '@/shared/utils/guid'

export default class FormWebQuestion {
  constructor({
    id,
    formwebid,
    question,
    additionalQuestion,
    questionType,
    order,
    formWebQuestionAlternatives
  } = {}) {
    this.id = id || GuidUtils.emptyGuid,
    this.formwebid = formwebid,
    this.question = question,
    this.additionalQuestion = additionalQuestion
    this.questionType = questionType
    this.order = order
    this.formWebQuestionAlternatives = formWebQuestionAlternatives
  }
}
