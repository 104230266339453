import SocialProjectManager from '../../clients/abdaSocialProjectManager'
import { merge } from 'lodash'

const resourceName = 'formweb'

const GetAll = (name, pagination) => SocialProjectManager.get(resourceName, {
  params: merge(
    { name: name },
      pagination)
  })
const GetById = id => SocialProjectManager.get(`${resourceName}/${id}`)
const ChangeActiveForm = id => SocialProjectManager.put(`${resourceName}/change-active/${id}`)
const Create = data => SocialProjectManager.post(resourceName, data)
const CreateQuestion = data => SocialProjectManager.post(`${resourceName}/create-question`, data)
const Update = data => SocialProjectManager.put(`${resourceName}/${data.id}`, data)
const Delete = id => SocialProjectManager.delete(`${resourceName}/${id}`)

const GetFormWebQuestionByID = formWebQuestionID => SocialProjectManager.get(`${resourceName}/formweb-question/${formWebQuestionID}`)
const GetAllQuestionsByFormWebID = id => SocialProjectManager.get(`${resourceName}/all-questions-formwebid/${id}`)
const DeleteQuestion = formWebQuestionID => SocialProjectManager.delete(`${resourceName}/delete-question/${formWebQuestionID}`)
const UpdateQuestion = data => SocialProjectManager.put(`${resourceName}/update-question`, data)

const WebFormRepository = {
  GetAll,
  GetById,
  Create,
  Update,
  Delete,
  ChangeActiveForm,
  CreateQuestion,
  GetFormWebQuestionByID,
  GetAllQuestionsByFormWebID,
  DeleteQuestion,
  UpdateQuestion
}

export default WebFormRepository
